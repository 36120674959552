import { Locale } from './locale'

export const server = 'https://ovvo.studio'

export const endpoints = {
  // NOTE: we want here to populate all the 'relation' fields (tags of course, but also any image etc.)
  projects: (locale: Locale) => `${server}/api/projects?populate=*&locale=${locale}`,
  companyInfos: (locale: Locale) => `${server}/api/company-infos?populate=*&locale=${locale}`,
  globalSettings: (locale: Locale) => `${server}/api/global?populate=*&locale=${locale}`,
  studiosPage: (locale: Locale) => `${server}/api/studios-page?populate=*&locale=${locale}`,
  graphql: () => `${server}/graphql`,
}

export const development = /ovvo.studio/.test(window.location.href) === false || /staging.ovvo.studio/.test(window.location.href)

export const devicePixelRatio = (
  // true ? 1 :
  window.innerWidth > 1024 ? 2 : // big screens === good GPU?
  1.5
)
