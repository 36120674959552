import { useState } from 'react'
import ReactPlayer from 'react-player'

import { globalData, reels } from 'data'

import { handleEvent, handlePointer } from 'some-utils/dom'
import { safeClassName, useLayoutEffects } from 'some-utils/npm/react'

import { useReelState } from './useReelState'

import './MobileReel.css'

export function MobileReel() {
  const state = useReelState()
  const [playing, setPlaying] = useState(false)

  const { ref } = useLayoutEffects<HTMLDivElement>(function* (div) {
    const [min, max] = [div.clientWidth, div.clientHeight].sort((a, b) => a - b)
    const wrappers = [...div.querySelectorAll('.OrientationWrapper')] as HTMLDivElement[]
    for (const wrapper of wrappers) {
      wrapper.style.width = `${max}px`
      wrapper.style.height = `${min}px`
      wrapper.style.top = `${max}px`
      wrapper.style.left = `0`
      wrapper.style.transformOrigin = `top left`
      wrapper.style.transform = `rotate(-90deg)`
    }

    const playButton = div.querySelector('.PlayButton') as HTMLDivElement
    const playerWrapper = div.querySelector('.PlayerWrapper') as HTMLDivElement

    // Via fullscreen API:
    yield handleEvent(document.documentElement, {
      fullscreenchange: () => {
        if (document.fullscreenElement) {
          setPlaying(true)
        } else {
          setPlaying(false)
        }
      },
    })

    yield handlePointer(playButton, {
      passive: false,
      onTap: async () => {
        try {
          await playerWrapper.requestFullscreen()
        } catch (error) {
          document.body.append(playerWrapper)
          setPlaying(true)
        }
      },
    })

    yield handlePointer(playerWrapper, {
      passive: false,
      onTap: () => {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        } else {
          div.prepend(playerWrapper)
          setPlaying(false)
        }
      },
    })
  }, [])

  return (
    <div ref={ref} className='MobileReel absolute-fill'>
      <div className='PlayerWrapper absolute-fill'>
        <div
          className='OrientationWrapper absolute'
        // style={{ pointerEvents: 'none' }}
        >
          <ReactPlayer
            ref={p => console.log(p)}
            playing={playing}
            controls
            playsinline
            width='100%'
            height='100%'
            url={reels[state.reelIndex].videoUrl()}
            onReady={() => {
              state.set({ videoIsReady: true })
            }}
          />
        </div>
      </div>

      <div className='absolute-fill'>
        <div className='CoverWrapper OrientationWrapper absolute'>
          {reels.map((reel, index) => (
            <img
              key={index}
              className={safeClassName(
                'Cover absolute-fill',
                index === state.reelIndex && 'active'
              )}
              alt='video cover'
              src={reel.cover().url()}
            />
          ))}
        </div>
      </div>

      <div className='UI absolute-fill'>
        <div className='Spacing' />

        <h1>
          {globalData.attributes.MenuNameReels}
        </h1>

        <div className='Spacing' />

        <ul>
          {reels.map((reel, index) => (
            <li key={index}>
              <span
                className={`button ${state.reelIndex === index ? 'active' : 'ui-dim'}`}
                onClick={() => {
                  state.set({
                    reelIndex: index,
                    videoIsReady: false,
                  })
                }}
              >
                {reel.title()}
              </span>
            </li>
          ))}
        </ul>

        <div style={{ flex: '1' }} />

        <div
          className='PlayButton'
          onClick={() => state.set({})}
        >
          <svg width="63" height="72" viewBox="0 0 63 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M62 34.268C63.3333 35.0378 63.3333 36.9622 62 37.7321L3.49999 71.507C2.16666 72.2768 0.499997 71.3146 0.499997 69.775L0.5 2.225C0.5 0.685401 2.16667 -0.276846 3.5 0.492954L62 34.268Z" fill="#D9D9D9" fillOpacity="0.8" />
          </svg>
        </div>

        <div className='Spacing' />

        <div className='OrientationIcon'>
          <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="32" stroke="white" />
            <rect x="34.5" y="15.5" width="15" height="32" transform="rotate(90 34.5 15.5)" stroke="white" strokeDasharray="2 2" />
          </svg>
        </div>

        <div className='Spacing' />
      </div>
    </div>
  )
}
