import { useEffects, useObservable } from 'some-utils/npm/react'
import { Link } from 'some-utils/npm/react/router'
import { firstLine, firstWord } from 'some-utils/string'

import { useAppContext } from 'AppContext'
import { CopyIcon, LocationIcon, TelIcon } from 'assets/svg'
import { companyInfos, getFilteredSortedCompanyInfos, globalData } from 'data'
import { navigate, staticRoutes } from 'state/navigation'
import { ovvoLogoOpening } from 'state/ui'
import { unifiedSearchState } from 'state/unified-search'
import { ovvoCopyToClipboard } from 'utils/copyToClipboard'
import { FacebookButton, InstagramButton, LinkedinButton, LocaleButton, VimeoButton } from 'view/components'
import { Search } from 'view/hud/components/svg'

import { closeMobileMenu, mobileMenuIsOpenObs } from './state'

import './MobileMenu.css'

const Middle = () => {
  return (
    <div className='flex column center gutter-8 ui ui-uppercase'>

      <Link
        className='HomeButton NavButton button flex row center'
        preventNavigation
        to='#'
        onLink={() => {
          // NOTE: Close the mobile first, since it can have side effects otherwise.
          closeMobileMenu()
          unifiedSearchState.visibleObs.setTrue()
        }}>
        <Search fill='var(--ui-color)' />
      </Link>

      <Link
        className='NewsButton NavButton button flex row center'
        preventNavigation
        to={staticRoutes.news}
        onLink={() => {
          // NOTE: Close the mobile first, since it can have side effects otherwise.
          closeMobileMenu()
          navigate(staticRoutes.news)
        }}
      >
        {globalData.attributes.MenuNameNews}
      </Link>

      <Link className='ProjectButton NavButton button flex row center'
        preventNavigation
        to={staticRoutes.searchProjects}
        onLink={() => {
          // NOTE: Close the mobile first, since it can have side effects otherwise.
          closeMobileMenu()
          navigate(staticRoutes.searchProjectsPixel)
        }}>
        {globalData.attributes.MenuNameProject}
      </Link>

      <Link
        className='NavButton button'
        preventNavigation
        to={staticRoutes.reels}
        onLink={() => {
          // NOTE: Close the mobile first, since it can have side effects otherwise.
          closeMobileMenu()
          navigate(staticRoutes.reels)
        }}
      >
        {globalData.attributes.MenuNameReels}
      </Link>

      <Link
        className='NavButton button'
        preventNavigation
        to={staticRoutes.studio}
        onLink={() => {
          // NOTE: Close the mobile first, since it can have side effects otherwise.
          closeMobileMenu()
          navigate(staticRoutes.studio)
        }}
      >
        {globalData.attributes.MenuNameStudio}
      </Link>

    </div>
  )
}

const Locales = () => {
  return (
    <div className='Locales flex row center gutter-32'>
      <LocaleButton fr />
      <LocaleButton en />
    </div>
  )
}

const Companies = () => {
  return (
    <div className='Companies flex column ui-dim gutter-4'>
      {getFilteredSortedCompanyInfos().map(company => {
        return (
          <div className='Company flex row gutter-8' key={company.id}>
            <span className='fill'>
              {firstWord(company.attributes.companyDisplayLocation)}
            </span>
            <span className='Icon'>
              <a
                className='Link'
                href={`tel:${firstLine(company.attributes.companyPhones).trim().replace(/\s/g, '')}`}
                target="_blank"
                rel='noreferrer'
              >
                <TelIcon />
              </a>
            </span>
            <span className='Icon'>
              <a
                className='Link'
                href="#copy"
                onClick={event => {
                  event.preventDefault()
                  ovvoCopyToClipboard(company.info())
                }}
              >
                <CopyIcon />
              </a>
            </span>
            <span className='Icon Location'>
              <a
                className='Link'
                href={company.attributes.companyMap}
              >
                <LocationIcon />
              </a>
            </span>
          </div>
        )
      })}
    </div>
  )
}

const Email = () => {
  const info = companyInfos.find(info => info.attributes.isMainCompany) ?? companyInfos[0]
  const email = firstWord(info.attributes.companyEmails)
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    ovvoCopyToClipboard(email)
  }
  return (
    <div className='Email ui-dim flex row center'>
      <a
        className='Link'
        href='#ok'
        dangerouslySetInnerHTML={{ __html: [...email].map(char => `<span>&#${char.charCodeAt(0)};</span>`).join('') }}
        onClick={onClick}
      />
    </div>
  )
}

const Bottom = () => {
  return (
    <div className='Bottom flex row center gutter-8'>
      <FacebookButton />
      <LinkedinButton />
      <VimeoButton />
      <InstagramButton />
    </div>
  )
}

export const MobileMenu = () => {
  const open = useObservable(mobileMenuIsOpenObs)
  const app = useAppContext()

  // "Theme" effect
  useEffects(function* () {
    let previous = app.themeObs.value
    const logoIsOpen = ovvoLogoOpening.createChild(false)
    yield logoIsOpen // Destroy the child when the effect is destroyed
    yield mobileMenuIsOpenObs.withValue(value => {
      if (value) {
        previous = app.themeObs.value
        app.themeObs.setValue('dark')
        logoIsOpen.setValue(true)
      } else {
        app.themeObs.setValue(previous)
        logoIsOpen.setValue(false)
      }
    })
  }, [])

  return !open ? null : (
    <div className='MobileMenu'>
      <div className='fill flex column space-between'>
        <Middle />
        <Locales />
        <Companies />
        <Email />
        <Bottom />
      </div>
    </div>
  )
}
