import { useState } from 'react'

import { handleKeyboard, handleWindow } from 'some-utils/dom'
import { AnimationFrame, pointerTypeObs, useEffects } from 'some-utils/npm/react'
import { DivRoute, Router, usePathname } from 'some-utils/npm/react/router'

import { AppContextProvider } from 'AppContext'
import { development } from 'config'
import { allProjectsBySlug, dataInit } from 'data'
import { Doc } from 'doc'
import { QualityAdaptater } from 'quality/QualityAdaptater'
import { QualityDebug } from 'quality/QualityDebug'
import { useGlobalStyle, useResponsive } from 'state/layout'
import { is404, staticRoutes } from 'state/navigation'
import { documentTitle } from 'utils/document-title'

import { DebugGrid } from 'view/DebugGrid'
import { Route404 } from 'view/Route404'
import { Cursor } from 'view/cursor'
import { Scene3D } from 'view/gl/Scene3D'
import { Home } from 'view/home/Home'
import { HUD } from 'view/hud/HUD'
import { Lightbox } from 'view/lightbox/Lightbox'
import { MobileMenu } from 'view/mobile-menu'
import { News } from 'view/news/News'
import { Project } from 'view/project/Project'
import { showreel } from 'view/reels'
import { SearchProject } from 'view/search-project'
import { Snackbar } from 'view/snackbar'
import { Studios } from 'view/studios'
import { UnifiedSearch } from 'view/unified-search'

import 'cheat'
import './App.css'

const DevicePointerType = () => {
  useEffects(function* () {
    yield pointerTypeObs.withValue(value => {
      document.documentElement.classList.toggle('touch', value === 'touch')
      document.documentElement.classList.toggle('mouse', value === 'mouse')
    })
  }, [])
  return null
}

const AppRouter = () => {
  const slugs = [...allProjectsBySlug.keys()]
  // return (
  //   <Router baseUrl={/\/en|fr/}>
  //     <div className='absolute-fill flex column center'>
  //       <a href="tel:1-408-555-5555">1-408-555-5555</a>
  //     </div>
  //   </Router>
  // )
  return (
    <Router baseUrl={/\/en|fr/}>
      <DebugGrid />
      <DevicePointerType />

      <Scene3D />

      <DivRoute path="/" exact transitionDuration={1}>
        <Home />
      </DivRoute>

      {slugs.map(slug => (
        <DivRoute key={slug} path={`/${slug}`} transitionDuration={1}>
          <Project project={allProjectsBySlug.get(slug)!} />
        </DivRoute>
      ))}

      <DivRoute path={staticRoutes.news} transitionDuration={1}>
        <News />
      </DivRoute>

      <DivRoute path={staticRoutes.searchProjects} transitionDuration={1}>
        <SearchProject />
      </DivRoute>

      {/* NOTE: no more route for Showreel, because the showreel needs to be preload, otherwise the iframe flickers. */}
      {/* <showreel.Permanent /> */}

      <DivRoute path={staticRoutes.reels} transitionDuration={1}>
        <showreel.Regular />
      </DivRoute>

      <DivRoute path={staticRoutes.studio} transitionDuration={1}>
        <Studios />
      </DivRoute>

      <DivRoute path={is404}>
        <Route404 />
      </DivRoute>

      <MobileMenu />
      <UnifiedSearch />
      <HUD />
      <Lightbox />
      <Snackbar />
    </Router>
  )
}

const HistoryNavigationWithArrow = () => {
  useEffects(function* () {
    yield handleKeyboard({
      element: document.documentElement,
      onDown: [
        ['ArrowLeft', () => window.history.back()],
        ['ArrowRight', () => window.history.forward()],
      ],
    })
    const onPopState = (event: PopStateEvent): void => {
      event.preventDefault()
    }
    window.addEventListener('popstate', onPopState)
    yield () => {
      window.removeEventListener('popstate', onPopState)
    }
  }, [])
  return null
}

const AppAndData = () => {
  const [dataOk, setDataOk] = useState(false)
  if (dataOk === false) {
    dataInit().then(() => {
      setDataOk(true)
    })
  }

  return (
    <>
      <HistoryNavigationWithArrow />

      {useResponsive().mobile === false && (
        <Cursor />
      )}

      {dataOk === false
        ? <div className='absolute-fill flex column center'>...</div>
        : <AppRouter />
      }
    </>
  )
}

const ViewportHeightHandler = () => {
  useEffects(function* () {
    yield handleWindow({
      executeOnResizeImmediately: true,
      onResize: ({ height }) => {
        document.documentElement.style.setProperty('--vh', `${height}px`)
      },
    })
  }, [])

  return null
}

export const App = () => {
  useGlobalStyle()

  const isDoc = usePathname().endsWith(staticRoutes.doc)
  documentTitle('')

  return (
    <AppContextProvider>
      <QualityAdaptater />
      {development && (
        <QualityDebug />
      )}
      <ViewportHeightHandler />
      {isDoc
        ? <Doc />
        : <AppAndData />
      }
      <AnimationFrame timeBeforeFade={development ? 20 : 180} />
    </AppContextProvider>
  )
}
